import { ComponentProps } from 'react'

import joinClassNames from 'utilities/joinClassNames'

import Text, { TextProps } from '../Text'
import styles from './styles.module.scss'

interface RootProps extends ComponentProps<'div'> {
  index: string
}
const Root = ({ index, className, children, ...props }: RootProps) => (
  <div className={joinClassNames(styles.root, className)} {...props}>
    <Text as="small" size="sm" color="element-inverse">
      {index}
    </Text>
    {children}
  </div>
)

const Title = (props: TextProps) => (
  <Text {...props} size="md" color="element-bold" />
)

const SubTitle = (props: TextProps) => (
  <Text {...props} size="sm" weight="regular" color="element-medium" />
)

const Section = {
  Root,
  Title,
  SubTitle,
}

export default Section
